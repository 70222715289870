import React, { useState, useEffect } from "react"
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { Typography, Grid, TextField } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
} from '@material-ui/pickers';
import moment from "moment"
import { withStyles } from "@material-ui/core/styles"
import CloseIcon from "@material-ui/icons/Close"
import IconButton from "@material-ui/core/IconButton"
import * as env from "./env"
import { useTranslation } from "react-i18next"
import { isRTL } from "./common"

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    fontFamily: {
        fontFamily: env.getReceiptUIType() === "brightline" ? "Futura PT Bold" : 'Duplet'
    }
})

const TimePopUp = (props) => {
    const { classes } = props
    const [validationMessage, setValidationMessage] = useState('');
    const [openDatePicker, setOpenDatePicker] = useState(false);
    const { t, i18n } = useTranslation()
    const currentLocale = i18n.language

    useEffect( () => {
        if (props.searchParams.get('time')) {
            getReceipt(props.searchParams.get('time'), props.receiptData)
        }
      }, [props.receiptData]);

    const getReceipt = (date) => {
        props.setTime(date)
        const plusOneHour = new Date(date).getTime() + (1 * 60 * 60 * 1000)
        const hourAgo = new Date(date).getTime() - (1 * 60 * 60 * 1000)
        const options = { hour12: false };
        const plusOneHourTime = new Date(plusOneHour).toLocaleTimeString('en-US', options)
        const hourAgoTime = new Date(hourAgo).toLocaleTimeString('en-US', options)
        const formattedDateOfPurchase = moment(props.dateTime).format('YYYY-MM-DD')
        const plusOneHourDate = moment(`${formattedDateOfPurchase} ${plusOneHourTime}`, 'YYYY-MM-DD HH:mm:ss').format();
        const hourAgoDate = moment(`${formattedDateOfPurchase} ${hourAgoTime}`, 'YYYY-MM-DD HH:mm:ss').format();

        var filteredData = props.receiptData.filter((d) => {
            if (d.entry_time >= hourAgoDate && d.entry_time <= plusOneHourDate) {
                return d
            }
        })
        if (Array.isArray(filteredData) && filteredData.length > 0) {
            props.setReceipts(filteredData)
            props.setReceiptData([])
            props.setTimePopUp(false)
        }
        else {
            props.setTime(null)
            props.searchParams.delete('time')
            setValidationMessage(t('noReceiptsAvailableCheckThePurchaseTime'))
        }
        props.searchParams.set("time", date);
        props.setSearchParams(props.searchParams)
    }

    const closePopUp = () => {
        if (validationMessage !== "") {
            props.setReceipts([])
            props.setShowNoReceiptFound(true)
        }
        props.setTimePopUp(false)
    }

    const DialogContent = withStyles((theme) => ({
        root: {
            padding: theme.spacing(2),
        },
    }))(MuiDialogContent);


    return (
        <>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Dialog aria-labelledby="customized-dialog-title" open={true} dir={isRTL(currentLocale) ? "rtl" : "ltr"}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ flexGrow: 1, marginLeft: 16, marginRight: 16 }}>{t('confirmPurchaseTime')}</div> {/* Flex item with text "Hello" */}
                        <IconButton aria-label="close" onClick={closePopUp}>
                            <CloseIcon />
                        </IconButton> {/* Flex item with Close button */}
                        </div>
                        <DialogContent dividers>
                            <Grid container spacing={3} direction="column">
                                <Grid item><Typography className={classes.fontFamily}>{t('weNeedAdditionalInformationToLookUpYourReceiptPleaseProvideApproximateTimeWhenYouShopped')}</Typography></Grid>
                                <Grid item>
                                    <KeyboardTimePicker
                                        open={openDatePicker}
                                        onOpen={() => setOpenDatePicker(true)}
                                        onClose={() => setOpenDatePicker(false)}
                                        margin="normal"
                                        id="time-picker"
                                        placeholder={t('chooseTime')}
                                        value={props.time}
                                        TextFieldComponent={(props) => (
                                            <TextField {...props} onClick={(e) => setOpenDatePicker(true)} />
                                        )}
                                        onChange={(date) => {
                                            getReceipt(date)
                                        }}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change time',
                                        }}
                                        InputProps={{
                                            className: classes.fontFamily
                                        }}
                                        InputLabelProps={{
                                            className: classes.fontFamily
                                        }}
                                    />
                                </Grid>
                                <Grid item className={classes.content}>
                                    {validationMessage &&
                                        <Typography className={classes.fontFamily}>{validationMessage}</Typography>}
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </Dialog>
                </MuiPickersUtilsProvider>
        </>

    )
}

export default withStyles(styles)(TimePopUp)
