import React, { useEffect, useState } from 'react';
import './App.css';
import * as env from './env'
import { withStyles } from "@material-ui/core/styles"
import Country from './Country';
import Translation from './Translation';
import { Grid } from "@material-ui/core"
import { useTranslation, Trans } from 'react-i18next';
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { isRTL } from './common'
import Loader from './Loader';
import * as api from './api';
import { useSearchParams } from "react-router-dom";
import englishTranslationText from './languages/english.json'

let logo = env.getReceiptUILogo()

const styles = () => ({
  bottomContainer: {
    bottom: '0px',
    textAlign: 'center',
    backgroundColor: '#DCDCDC',
    padding: '15px',
    justifyContent: 'center',
    margin: '0px !important',
    width: '100% !important'
  },
  link: {
    textDecoration: 'none',
    color: '#9B28C4',
    whiteSpace: 'nowrap'
  }
})

function App(props) {
  const { classes } = props
  const { t, i18n } = useTranslation()
  let currentLocale = i18n.language;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const allLanguages = localStorage.getItem("allLanguages") ? JSON.parse(localStorage.getItem("allLanguages")) : [{ "id": 7, "code": "en-US", "name": "English" }]
  let currentLanguageCode = localStorage.getItem("currentLocale") ? JSON.parse(localStorage.getItem("currentLocale")) : "en-US"
  const [searchParams, setSearchParams] = useSearchParams({ ["hl"]: currentLanguageCode });
  const translationText = JSON.parse(localStorage.getItem("translationText"))
  let currentLanguage = Array.isArray(allLanguages) && allLanguages.length > 0 ? allLanguages.find(lang => {
    return lang.code === currentLanguageCode
  }) : null
  if (!currentLanguage) {
    currentLanguage = allLanguages.find(l => l.code === currentLanguageCode)
  }
  const [isLoading, setIsLoading] = useState(true)
  const { afterYourCreditCardIsChargedYouCanFindYourReceiptHere } = t("description")

  useEffect(() => {
    (async function mount() {
      const queryParams = new URLSearchParams(window.location.search);
      const languageCode = queryParams.get('hl');
      const countryName = queryParams.get('country_name');
      let allLanguagesresponse = await api.getAllLanguages()
      if (!allLanguagesresponse) {
        allLanguagesresponse = allLanguages
      }
      if (currentLocale !== languageCode) {
        const isPresent = allLanguagesresponse.some(lang =>
          Object.values(lang).some(code =>
            typeof code === 'string' && code === languageCode
          )
        );
        if (isPresent) {
          currentLocale = languageCode
          localStorage.setItem("currentLocale", JSON.stringify(currentLocale))
        }
      }
      let translationResponse = await api.getTranslations(currentLocale)
      if (!translationResponse || translationResponse.error === true) {
        translationResponse = translationText ? translationText : JSON.stringify(englishTranslationText)
      }
      i18n.addResourceBundle(currentLocale, 'translation', JSON.parse(translationResponse), true, true);
      i18n.changeLanguage(currentLocale)
      localStorage.setItem("allLanguages", JSON.stringify(allLanguagesresponse))
      localStorage.setItem("translationText", JSON.stringify(translationResponse))
      searchParams.set('hl', currentLocale);

      // Construct the new URL with the updated search params
      const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
      // Update the URL without reloading the page
      window.history.pushState({}, '', newUrl);
      setIsLoading(false)
    })();
  }, []);

  return (
    <div style={{ direction: isRTL(currentLocale) ? "rtl" : "" }}>
      {(env.getReceiptUIType() !== 'ZIPPIN' && env.getReceiptUIType() !== "AMEX" && env.getReceiptUIType() !== "AMEXVIVINTARENA" && env.getReceiptUIType() !== "SSPDUBLIN" && !isMobile) &&
        <div style={{
          float: isRTL(currentLocale) ? "left" : "right",
          top: 0,
          right: 0,
          padding: "10px"
        }}>
          <Translation />
        </div>}
      <div style={{ backgroundColor: env.getReceiptUIType() === 'ZIPPIN' ? 'black' : 'none' }} className={env.getReceiptUIType() === "brightline" ? "App brightline-font" : "App"}>
        <div className="App-header">
          {env.getReceiptUIStoreName &&
            <div style={{ textAlign: 'center' }}>
              <h3 style={{ margin: 0 }}>
                {env.getReceiptUIStoreName()}</h3>
            </div>}
          {!isMobile && env.getReceiptUIType() !== "ZIPPIN" &&
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: isRTL(currentLocale) ? 0 : 250, marginRight: isRTL(currentLocale) ? 250 : 0 }}>
              <div>
                {logo && <a href="https://www.getzippin.com" target="_blank" rel="noreferrer"><img src={logo} className="App-logo" alt="logo" /></a>}
              </div>
            </div>
          }
          {(isMobile || env.getReceiptUIType() === "ZIPPIN") &&
            <div>
              {logo && <a href="https://www.getzippin.com" target="_blank" rel="noreferrer"><img src={logo} className="App-logo" alt="logo" /></a>}
            </div>
          }
        </div>

      </div>
      {isLoading === false ?
        <div>
          {!isMobile &&
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: isRTL(currentLocale) ? 'flex-start' : 'flex-end',
              alignItems: 'center',
              direction: isRTL(currentLocale) ? 'rtl' : 'ltr',
            }}>

              <div style={{ marginLeft: isRTL(currentLocale) ? 0 : 'auto', paddingLeft: isRTL(currentLocale) ? 0 : (env.getReceiptUIType() === 'ZIPPIN' || env.getReceiptUIType() === "SSPDUBLIN" || env.getReceiptUIType() === "AMEX" || env.getReceiptUIType() === "AMEXVIVINTARENA") ? 220 : 0, marginRight: isRTL(currentLocale) ? 'auto' : 0, paddingRight: isRTL(currentLocale) ? (env.getReceiptUIType() === 'ZIPPIN' || env.getReceiptUIType() === "SSPDUBLIN" || env.getReceiptUIType() === "AMEX" || env.getReceiptUIType() === "AMEXVIVINTARENA") ? 220 : 0 : 0 }}>
                {env.getReceiptUIType() === "lulu" && <p style={{ textAlign: 'center', fontFamily: 'Duplet' }}><Trans i18nKey={afterYourCreditCardIsChargedYouCanFindYourReceiptHere}
                  values={{ receipt: `${t('receipt')}` }}
                  components={{
                    receipt: <strong />
                  }} /></p>}
                <h3 style={{ textAlign: 'center' }} className={env.getReceiptUIType() === "brightline" ? "header-font" : "zippin-font"}>
                  {t('findMyReceipt')}
                </h3>
              </div>

              <div style={{ marginLeft: isRTL(currentLocale) ? 0 : 'auto', marginRight: isRTL(currentLocale) ? 'auto' : 0 }}>
                {(env.getReceiptUIType() === "ZIPPIN" || env.getReceiptUIType() === "AMEX" || env.getReceiptUIType() === "AMEXVIVINTARENA" || env.getReceiptUIType() === "SSPDUBLIN") && <Translation />}
              </div>
            </div>
          }
          {isMobile &&
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: isRTL(currentLocale) ? 'flex-start' : 'flex-end',
            }}>
              <div style={{ marginLeft: isRTL(currentLocale) ? 0 : 'auto', marginRight: isRTL(currentLocale) ? 'auto' : 0 }}>
                <Translation />
              </div>
              <div style={{ textAlign: "center" }}>
                {env.getReceiptUIType() === "lulu" && <p style={{ textAlign: 'center', fontFamily: 'Duplet' }}><Trans i18nKey={afterYourCreditCardIsChargedYouCanFindYourReceiptHere}
                  values={{ receipt: `${t('receipt')}` }}
                  components={{
                    receipt: <strong />
                  }} /></p>}
                <h3 className={env.getReceiptUIType() === "brightline" ? "header-font" : "zippin-font"}>
                  {t('findMyReceipt')}
                </h3>
              </div>
            </div>
          }
          <Country /></div> : <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}><Loader /> </div>}
      {env.getReceiptUIType() === 'ZIPPIN' &&
        <Grid container spacing={2} justifyContent='space-between' className={classes.bottomContainer}>
          <Grid xs={12} sm={4} md={1} lg={1} item><a className={classes.link} href="https://www.getzippin.com/privacy" target="_blank" rel="noreferrer">{t('privacyPolicy')}</a></Grid>
          <Grid xs={12} sm={4} md={10} lg={10} item><a className={classes.link} href="https://www.getzippin.com/shoppers#faqs" target="_blank" rel="noreferrer">{t('faqPage')}</a></Grid>
          <Grid style={{ whiteSpace: 'nowrap' }} xs={12} sm={4} md={1} lg={1} item>© Zippin {new Date().getFullYear()}</Grid>
        </Grid>
      }
    </div>
  );
}

export default withStyles(styles)(App)

