import * as env from './env'

export async function getAllLanguages() {
  try {
    const response = await fetch(`${env.getReactAppZippinPlatformAPI()}/applications/receipts-microsite/translations`, {
      method: 'GET'
    });
    const json = await response.json();
    return json;
  } catch (error) {
    console.log(`getAllLanguages = ${error}`);
  }
}

export async function getTranslations(lang) {
  try {
    const response = await fetch(`${env.getReactAppZippinPlatformAPI()}/applications/receipts-microsite/translations/${lang}`, {
      method: 'GET'
    });
    const json = await response.json();
    return json;
  } catch (error) {
    console.log(`getTranslations = ${error}`);
  }
}