import React from 'react';
import * as env from './env'
import { useTranslation, Trans } from "react-i18next"
import { isRTL } from './common'

let iosImg = '/images/apple-app-store.png';
let androidImg = '/images/google-play-store.png';

const ZippinApp = (props) => {
	const { t, i18n } = useTranslation()
	let currentLocale = i18n.language;
	const { getTheLuluShoppingApp, sendFeedbackToLuluMailId, sendFeedbackToAlmeeraMailId, sendFeedbackToSspdublinMailId, moreLuluInformationAvailableHere, moreAlmeeraInformationAvailableHere, toLearnMoreAboutMrktOrSubmitFeedbackClickHere } = t("description")
	if (env.getReceiptUIType() === "brightline") {
		return (
			<div style={{ textAlign: 'center' }}>
				<p><Trans i18nKey={toLearnMoreAboutMrktOrSubmitFeedbackClickHere}
					values={{ MRKTSubmitFeedback: t('here') }} components={{
						MRKTSubmitFeedback: <a target="_blank" rel="noopener noreferrer" href={env.getReceiptUILink()}></a>,
					}} />.</p>
			</div>
		)
	} else if (env.getReceiptUIType() === "lulu") {
		return (
			<div style={{ textAlign: 'center' }}>
				<h3><Trans i18nKey={getTheLuluShoppingApp}
					values={{ luluShopping: `${t('luluShopping')}` }}
					components={{
						luluShopping: <a target="_blank" style={{ color: "#0E86D4", textDecoration: "none" }} rel="noopener noreferrer" href="https://qrs.ly/vcag80j "></a>,
					}} /></h3>
				<p><Trans i18nKey={moreLuluInformationAvailableHere}
					values={{ luluInformation: t('here') }} components={{
						luluInformation: <a target="_blank" style={{ color: "#0E86D4" }} rel="noopener noreferrer" href={env.getReceiptUILink()}></a>,
					}} />.</p>
				<p><Trans i18nKey={sendFeedbackToLuluMailId}
					values={{ luluMailId: "LE3080@qa.lulumea.com" }} components={{
						luluMailId: <a href="mailto:LE3080@qa.lulumea.com" style={{ color: "#0E86D4" }}></a>,
					}} /></p>
			</div>
		)
	} else if (env.getReceiptUIType() === "almeera") {
		return (
			<div style={{ textAlign: 'center', paddingTop: 40 }}>
				<h3>{t('getTheAlMeeraApp')}</h3>
				<div>
					<a href='https://apps.apple.com/in/app/meera-rewards/id1445590345' style={{ marginRight: isRTL(currentLocale) ? 0 : 10 }}>
						<img src={iosImg} alt="Apple App Store" />
					</a>
					<a href='https://play.google.com/store/apps/details?id=googlecom.codelab.meera&hl=en_US&gl=US&pli=1' style={{ marginRight: isRTL(currentLocale) ? 10 : 0 }}>
						<img src={androidImg} alt="Google Play Store" />
					</a>
					<p><Trans i18nKey={moreAlmeeraInformationAvailableHere}
						values={{ almeeraInformation: t('here') }} components={{
							almeeraInformation: <a href="https://www.almeera.com.qa/index.php"></a>,
						}} />.</p>
				</div>
				<div style={{ textAlign: "center" }}>
					<span>
						<Trans i18nKey={sendFeedbackToAlmeeraMailId}
							values={{ almeeraMailId: "customerservice@almeera.com.qa" }} components={{
								almeeraMailId: <a href="mailto:customerservice@almeera.com.qa"></a>,
							}} />
					</span>
					<p>{t('Tel')}: 40119111 – 40119112</p>
				</div>
			</div>
		)
	}
	else if (env.getReceiptUIType() === "SSPDUBLIN") {
		return (
			<div style={{ textAlign: "center" }}>
				<p>{t('itCanTakeUpToThirtyMinutesForYourReceiptToBecomeAvailable')}</p>
				<span>
					<Trans i18nKey={sendFeedbackToSspdublinMailId}
						values={{ sspdublinMailId: "Customer.care@ssp.uk.com" }} components={{
							sspdublinMailId: <a href="mailto:Customer.care@ssp.uk.com"></a>,
						}} />
				</span>
			</div>
		)
	}
	else {
		return null
	}
}

export default ZippinApp