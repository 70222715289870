/**
 * Get the Receipt UI type. Zippin or Amex
 */
export const getReceiptUIType = () => {
    return process.env.REACT_APP_RECEIPT_UI_TYPE
}

/**
 * Get the base URL for tenant backend API.
 */
 export const getTenantBackendBaseUrl = () => {
  return process.env.REACT_APP_TENANT_BACKEND_API
}

/**
 * Get the base URL for tenant backend API.
 */
export const getReactAppZippinPlatformAPI = () => {
  return process.env.REACT_APP_ZIPPIN_PLATFORM_API
}

/**
 * Get the receipt UI store name.
 */
 export const getReceiptUIStoreName = () => {
  return process.env.REACT_APP_RECEIPT_UI_STORE_NAME
}

/**
 * Get the receipt UI store link.
 */
 export const getReceiptUILink = () => {
  return process.env.REACT_APP_RECEIPT_UI_LINK
}

/**
 * Get the receipt UI Logo.
 */
 export const getReceiptUILogo = () => {
  return process.env.REACT_APP_RECEIPT_UI_LOGO
}

/**
 * Get the receipt UI country.
 */
 export const getReceiptUICountry = () => {
  return process.env.REACT_APP_RECEIPT_UI_COUNTRY
}

export const hideTransactionId = () => {
  return process.env.REACT_APP_HIDE_TRANSACTION_ID || false
}